import React from "react";
import Chart from "react-apexcharts";
import formatNumber from "../../../../utils/formatNumber";

const CustomerBarChart = ({ BarChartData }) => {
  const truncatedCategories = BarChartData.map((data) =>
    data.customerOrganization.length > 12
      ? data.customerOrganization.substring(0, 12) + "..."
      : data.customerOrganization
  );
  const customerChartOptions = {
    chart: {
      height: 250,
      type: "bar",
      stacked: false,
      toolbar: {
        show: false
      }
    },
    colors: ["#da4453"],
    xaxis: {
      categories: truncatedCategories
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded"
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: 2,
      colors: ["transparent"]
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: function (val, opts) {
          const customer =
            BarChartData[opts.dataPointIndex].customerOrganization;
          const currency = BarChartData[opts.dataPointIndex].currency;
          return `${val} ${currency}<br>${customer}`;
        }
      },
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const label = BarChartData[dataPointIndex].customerOrganization;
        const value = series[seriesIndex][dataPointIndex];
        const currency = BarChartData[dataPointIndex].currency;
        const formattedValue = formatNumber(value);
        return `
            <div class="arrow_box">
            <label>${label}</label>
            <span>${currency} - ${formattedValue}</span>
          </div>
        `;
      }
    }
  };

  const seriesData = BarChartData.map((data) =>
    parseFloat(data.totalPendingAmount)
  );

  return (
    <div className="app">
      <div className="row">
        <div className="pay_cust_chart">
          <Chart
            options={customerChartOptions}
            series={[{ data: seriesData }]}
            type="bar"
            className="customer_bar_charts"
            height={320}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomerBarChart;
