import React, { useState, useEffect } from "react";
import { Space, Dropdown, Menu, Tooltip } from "antd";
import {
  DownOutlined,
  MoreOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined
} from "@ant-design/icons";
import "./layout.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLogoutNull } from "../../actions/login/LoginAction";
import {
  setCompanyDetails,
  seCompanyNull
} from "../../actions/companyDetails/CompanyDetailsAction";
import imgLogout from "../../assets/images/logout.png";
import HeaderService from "../../services/header-service";
import { setTableSearchInput, setTableSortingData } from "../../actions/tableListing/tableSortingActionData";

const Header = ({
  collapsed,
  toggleSidebar,
  mobileMenuToggle,
  toggleMobileMenuCollapsed
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showMenu, setShowMenu] = useState(false);
  const [userFullname, setUserFullname] = useState("");
  const [companyListData, setCompanyListData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const userDetails = useSelector(
    (state) => state.loginReducer.loginDetials.userDetails
  );

  useEffect(() => {
    let fullname = userDetails.firstName + " " + userDetails.lastName;
    setUserFullname(fullname);
    handleGetKnackCompanyListData();
  }, [userDetails]);

  const handleWindowResize = () => {
    const headerTitle = document.querySelector(".menu_title");
    const selectCompany = document.querySelector(".select_company");
    const profileSection = document.querySelector(".profile_section");
    if (window.innerWidth >= 768) {
      headerTitle.style.display = "block";
      selectCompany.style.display = "block";
      profileSection.style.display = "block";
      setShowMenu(false);
    } else if (window.innerWidth <= 767) {
      setShowMenu(true);
      headerTitle.style.display = "block";
      selectCompany.style.display = "none";
      profileSection.style.display = "none";
    }
  };

  useEffect(() => {
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
    const selectCompany = document.querySelector(".select_company");
    const profileSection = document.querySelector(".profile_section");
    const headerTitle = document.querySelector(".menu_title");
    if (selectCompany && profileSection) {
      if (showMenu) {
        selectCompany.style.display = "block";
        profileSection.style.display = "block";
        headerTitle.style.display = "none";
      } else {
        selectCompany.style.display = "none";
        profileSection.style.display = "none";
        headerTitle.style.display = "block";
      }
    }
  };

  const handleGetKnackCompanyListData = () => {
    HeaderService.getKnackCompanyListAPI()
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finalResponse = response.data.responseData;
          if (finalResponse.length > 0) {
            setCompanyListData(finalResponse);
            dispatch(setCompanyDetails(finalResponse[0]));
            setSelectedCompany(finalResponse[0]);
          } else {
            setCompanyListData([]);
            setSelectedCompany(null);
          }
        }
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };

  const handleLogout = () => {
    dispatch(setLogoutNull(null));
    dispatch(seCompanyNull(null));
    dispatch(setTableSortingData(null));
    dispatch(setTableSearchInput(""));
    navigate("/");
  };
  function getCompanySortName(input) {
    return input
      .split(" ")
      .map((word) => word[0])
      .join("");
  }
  const handleCompanyOnChange = (e) => {
    const companyDetails = companyListData.find(
      (x) => x.company_Code.toString() === e.key
    );
    dispatch(setCompanyDetails(companyDetails));
    setSelectedCompany(companyDetails);
  };
  const getFirstNameLetter = (name) => {
    const nameParts = name.trim().split(" ");
    const initials = nameParts.map((part) => part[0]).join("");
    return initials.toUpperCase();
  };
  return (
    <React.Fragment>
      <header>
        <div className="header_container d-flex justify-content-between p-2">
          <div className="header_left d-flex align-items-center">
            <div
              className="menu_bar desktop__menubtn"
              onClick={() => toggleSidebar()}
            >
              <span className="sidebar__toggle mx-2">
                {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              </span>
            </div>
            <div
              className="menu_bar mobile__menubtn"
              onClick={() => toggleMobileMenuCollapsed()}
            >
              <span className="sidebar__toggle mx-2">
                {mobileMenuToggle ? (
                  <MenuUnfoldOutlined />
                ) : (
                  <MenuFoldOutlined />
                )}
              </span>
            </div>
            <div className="menu_title">
              Galaxy {userDetails.isEmployee ? "Employee" : "Customer"} Portal
            </div>
          </div>
          <div className="header_right d-flex">
            <div className="select_company align-content-center">
              <span className="px-2 company_name">Company Name:</span>
              <Dropdown
                overlay={
                  <Menu
                    className="custom_select"
                    onClick={handleCompanyOnChange}
                    disabled={
                      window.location.pathname.includes("/dashboard/")
                        ? false
                        : true
                    }
                    selectedKeys={selectedCompany ? [selectedCompany.id] : []}
                  >
                    <Menu.Item key="">Select</Menu.Item>
                    {companyListData.map((item) => {
                      return (
                        <Menu.Item key={item.company_Code}>
                          <Tooltip placement="bottom" title={item.name}>
                            {getCompanySortName(item.name)}
                          </Tooltip>
                        </Menu.Item>
                      );
                    })}
                  </Menu>
                }
                placement="bottomLeft"
                trigger={["click"]}
              >
                <span>
                  {selectedCompany
                    ? getCompanySortName(selectedCompany.name)
                    : "Select"}
                  <DownOutlined className="p-2" />
                </span>
              </Dropdown>
            </div>
            <div className="profile_section p-2">
              <Space size="small">
                <span className="user_letter_profile">
                  {getFirstNameLetter(userFullname)}
                </span>
                <Dropdown
                  overlay={
                    <Menu className="logout_select">
                      <Menu.Item
                        onClick={handleLogout}
                        icon={<img src={imgLogout} alt="img" />}
                      >
                        Logout
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={["click"]}
                  className="user_profile"
                >
                  <span title={userFullname}>
                    {userFullname?.length > 12
                      ? userFullname.substr(0, 12).concat("..")
                      : userFullname}
                    <DownOutlined className="p-2" />
                  </span>
                </Dropdown>
              </Space>
            </div>
          </div>
          <div className="menu_icon" onClick={toggleMenu}>
            <MoreOutlined className="mobileiCons" />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
