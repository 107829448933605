import React, { Fragment, useEffect, useState } from "react";
import { Input, Card } from "antd";
import CustomerBarChart from "./CustomerBarChart";
import { NotificationManager } from "react-notifications";
import CurrencyOutstandingTbl from "./CurrencyOutstandingTbl";
import paymentService from "../../../../services/Payment/payment-service";
import { useSelector } from "react-redux";

const { Search } = Input;

const CustomerTab = ({ selectAnalysisType }) => {
  const companyDetails = useSelector(
    (state) => state.CompanyDetails.companyDetials
  );

  const [isTblLoading, setIsTblLoading] = useState(false);
  const [customerBarChartData, setCustomerBarChartData] = useState([]);
  const [currencyWiseDataTbl, setCurrencyWiseDataTbl] = useState([]);
  const [totalPendingLCY, setTotalPendingLCY] = useState(0);
  const [chartSearchtext, setChartSearchtext] = useState("");

  useEffect(() => {
    handleGetPaymentChartData();
    handleGetPaymentCurrencyTableData();
  }, [selectAnalysisType]);

  useEffect(() => {
    if (chartSearchtext.trim() !== "") {
      handleGetPaymentChartData();
    }
  }, [chartSearchtext]);

  const handleGetPaymentChartData = () => {
    let inputData = {
      company_Code: companyDetails ? companyDetails.company_Code : "",
      type: selectAnalysisType,
      search: chartSearchtext.trim(),
      pageindex: "1",
      pagesize: "12"
    };
    paymentService
      .getPaymentBarChartDataAPI(inputData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finaldata = response.data.responseData;
          if (finaldata.customerPaymentList?.length > 0) {
            setCustomerBarChartData(finaldata.customerPaymentList);
          } else {
            setCustomerBarChartData([]);
          }
        } else {
          NotificationManager.error(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };
  const handleGetPaymentCurrencyTableData = () => {
    setIsTblLoading(true);
    let inputData = {
      company_Code: companyDetails ? companyDetails.company_Code : "",
      type: selectAnalysisType
    };
    paymentService
      .getPaymentCurrencyWiseDataAPI(inputData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finaldata = response.data.responseData;
          if (finaldata.totalPaymentListResponse?.length > 0) {
            setCurrencyWiseDataTbl(finaldata.totalPaymentListResponse);
            setTotalPendingLCY(finaldata.total);
          } else {
            setCurrencyWiseDataTbl([]);
            setTotalPendingLCY(0);
          }
        } else {
          NotificationManager.error(response.data.message);
        }
        setIsTblLoading(false);
      })
      .catch((error) => {
        setIsTblLoading(false);
        console.log(error, "Error");
      });
  };
  const handleOnSearchData = (value) => {
    setChartSearchtext(value);
  };
  const handleChartSearchOnchange = (e) => {
    setChartSearchtext(e.target.value);
  };
  return (
    <Fragment>
      <div className="pay_customer_tab">
        <div className="row">
          <div className="col-md-6">
            <Card>
              <div className="cust_card">
                <div className="d-flex justify-content-between">
                  <label className="card_lbl">Customers</label>
                  <div className="pay_search_int">
                    <Search
                      placeholder="Search..."
                      allowClear
                      className="dash_search"
                      size="large"
                      value={chartSearchtext}
                      onSearch={handleOnSearchData}
                      onChange={handleChartSearchOnchange}
                    />
                  </div>
                </div>
                <CustomerBarChart BarChartData={customerBarChartData} />
              </div>
            </Card>
          </div>
          <div className="col-md-6">
            <Card>
              <div className="d-flex justify-content-between">
                <label className="card_lbl">Currency wise outstanding</label>
              </div>
              <div className="cust_out_tbl">
                <CurrencyOutstandingTbl
                  tableData={currencyWiseDataTbl}
                  totalLCY={totalPendingLCY}
                  isLoading={isTblLoading}
                />
              </div>
            </Card>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CustomerTab;
