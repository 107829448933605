import React, { Fragment, useState, useEffect } from "react";
import {
  SmallOrder,
  SmallMaterial,
  SmallQuantity,
  SmallMTQ,
} from "../imageAssets";
import { Card, Input, Tabs, Button, Spin } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import GlobalFilterDrawer from "../../../components/GlobalFilterDrawer";
import { NotificationManager } from "react-notifications";
import EliteCardService from "../../../services/elite-cards-service";
import DashboardService from "../../../services/o2w-dashboard-service";
import { setTableSearchInput, setTableSortingData } from "../../../actions/tableListing/tableSortingActionData";
import { checkActiveTabNo } from "../../../helper/commonData";
import { useNavigate } from "react-router-dom";
import AllTab from "../../../components/DashboardTabs/AllTab";
import POTab from "../../../components/DashboardTabs/POTab";
import MaterialTab from "../../../components/DashboardTabs/MaterialTab";
import ContainerTab from "../../../components/DashboardTabs/ContainerTab";

const { Search } = Input;
const { TabPane } = Tabs;

const LogisticDashboard = ({ type }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userDetails = useSelector(
    (state) => state.loginReducer.loginDetials.userDetails
  );
  const companyDetails = useSelector(
    (state) => state.CompanyDetails.companyDetials
  );

  const tableSortingData = useSelector(
    (state) => state.TableSortingData.tableListData
  );

  const tableSearchInput = useSelector(
    (state) => state.TableSortingData.tableSearchInput
  );

  const [isLoading, setIsLoading] = useState(false);
  const [isCardsLoading, setIsCardsLoading] = useState(false);
  const [isGlobalFilter, setIsGlobalFilter] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [tabTableData, setTabTableData] = useState({});
  const [cardClasses, setCardClasses] = useState("");
  const [activeTabs, setActiveTabs] = useState("");
  const [isEmployee, setIsEmployee] = useState(false);
  const [tableSearchData, setTableSearchData] = useState(tableSearchInput);
  const [applyFilterObj, setApplyFilterObj] = useState({});

  useEffect(() => {
    if (tableSortingData === null) {
      dispatch(
        setTableSortingData({
          currentPage: 1,
          orderBy: "desc",
          sortColumn: "",
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    if (userDetails.isEmployee) {
      setCardClasses("col-xl-3 col-lg-6 col-12");
    } else {
      setCardClasses("col-xl-4 col-lg-6 col-12");
    }
  }, [userDetails.isEmployee]);

  useEffect(() => {
    setIsEmployee(userDetails.isEmployee);
  }, []);

  useEffect(() => {
    let tabKey = checkActiveTabNo(type);
    setActiveTabs(tabKey);
  }, [type]);

  useEffect(() => {
    if (Object.keys(applyFilterObj).length > 0) {
      handleGetDashboardCount();
      handleGetTabsDashboardData();
    }
  }, [applyFilterObj]);

  useEffect(() => {
    if (tableSortingData?.sortColumn) {
      handleGetTabsDashboardData();
    }
  }, [tableSortingData]);

  useEffect(() => {
    handleGetTabsDashboardData();
    handleGetDashboardCount();
  }, [companyDetails]);

  const handleGetTabsDashboardData = async () => {
    let sortColumnName =
      type === "material"
        ? "customer_PO_Date"
        : type === "invoice"
        ? "customer_PO_Date"
        : type === "vehicle"
        ? "container_Or_Vehicle_ID"
        : type === "assumption"
        ? "estimated_Plan_Date"
        : "customer_PO_Date";
    setIsLoading(true);
    let inputData = {
      pageindex: tableSortingData !== null ? tableSortingData?.currentPage : 1,
      pagesize: 10,
      sortcolumn:
        tableSortingData !== null
          ? tableSortingData?.sortColumn
          : sortColumnName,
      orderBy: tableSortingData !== null ? tableSortingData?.orderBy : "desc",
      tabName: "all",
      search: "",
      company_Code: companyDetails ? companyDetails.company_Code : "",
      general_Manager:
        Object.keys(applyFilterObj).length > 0 && applyFilterObj.generalManager
          ? applyFilterObj.generalManager.join(",")
          : "",
      regional_Manager:
        Object.keys(applyFilterObj).length > 0 && applyFilterObj.regionalManager
          ? applyFilterObj.regionalManager.join(",")
          : "",
      area_Manager:
        Object.keys(applyFilterObj).length > 0 && applyFilterObj.areaManager
          ? applyFilterObj.areaManager.join(",")
          : "",
      groupIds:
        Object.keys(applyFilterObj).length > 0 && applyFilterObj.groupName
          ? applyFilterObj.groupName.join(",")
          : "",
      customer_OrganizationIds:
        Object.keys(applyFilterObj).length > 0 &&
        applyFilterObj.custOrganization
          ? applyFilterObj.custOrganization.join(",")
          : "",
    };
    await DashboardService.getTabsDashboardDataAPI(inputData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finalResponse = response.data.responseData;
          setIsLoading(false);
          if (finalResponse.totalRecords !== "0") {
            setTabTableData(finalResponse);
          } else {
            setIsLoading(false);
            setTabTableData({});
          }
        }
      })
      .catch((error) => {
        console.log(error, "Error");
        setIsLoading(false);
      });
  };

  const handleGetDashboardCount = () => {
    setIsCardsLoading(true);
    let inputData = {
      general_Manager:
        Object.keys(applyFilterObj).length > 0 && applyFilterObj.generalManager
          ? applyFilterObj.generalManager.join(",")
          : "",
      regional_Manager:
        Object.keys(applyFilterObj).length > 0 && applyFilterObj.regionalManager
          ? applyFilterObj.regionalManager.join(",")
          : "",
      area_Manager:
        Object.keys(applyFilterObj).length > 0 && applyFilterObj.areaManager
          ? applyFilterObj.areaManager.join(",")
          : "",
      company_Code: companyDetails ? companyDetails.company_Code : "",
      groupIds:
        Object.keys(applyFilterObj).length > 0 && applyFilterObj.groupName
          ? applyFilterObj.groupName.join(",")
          : "",
      customer_OrganizationIds:
        Object.keys(applyFilterObj).length > 0 &&
        applyFilterObj.custOrganization
          ? applyFilterObj.custOrganization.join(",")
          : "",
    };

    if (isGlobalFilter) {
      showFilterDrawer();
    }
    EliteCardService.getO2wTileDashboardCountsAPI(inputData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          let objData = response.data.responseData;
          setIsCardsLoading(false);
        } else {
          NotificationManager.error(response.data.message);
          setIsCardsLoading(false);
        }
      })
      .catch((error) => {
        setIsCardsLoading(false);
        console.log(error, "Error");
      });
  };
  const handleOnSearchData = (value) => {
    setTableSearchData(value);
    dispatch(setTableSearchInput(value));
    let tempTableObjColumn = {
      currentPage: 1,
      orderBy: "desc",
      sortColumn: ""
    };
    handleGetTabsDashboardData(value, tempTableObjColumn);
  };

  const handleTblSearchChange = (e) => {
    const { value } = e.target;
    setTableSearchData(value);
    dispatch(setTableSearchInput(value));
  };
  const handleTabChange = (key) => {
    let tabName = "";
    setTableSearchData("");
    dispatch(setTableSearchInput());
    switch (key) {
      case "1":
        tabName = "all";
        dispatch(
          setTableSortingData({
            sortColumn: "customer_PO_Date", /// default sort column
            orderBy: "desc",
            currentPage: 1
          })
        );
        break;
      case "2":
        tabName = "po";
        dispatch(
          setTableSortingData({
            sortColumn: "customer_PO_Date", /// default sort column
            orderBy: "desc",
            currentPage: 1
          })
        );
        break;
      case "3":
        tabName = "material";
        dispatch(
          setTableSortingData({
            sortColumn: "customer_PO_Date", /// default sort column
            orderBy: "desc",
            currentPage: 1
          })
        );
        break;
      case "4":
        tabName = "invoice";
        dispatch(
          setTableSortingData({
            sortColumn: "customer_PO_Date", /// default sort column
            orderBy: "desc",
            currentPage: 1
          })
        );
        break;
      case "5":
        tabName = "vehicle";
        dispatch(
          setTableSortingData({
            sortColumn: "container_Or_Vehicle_ID", /// default sort column
            orderBy: "desc",
            currentPage: 1
          })
        );
        break;

      default:
        tabName = "all";
        break;
    }

    navigate(`/dashboard/${tabName}`);
    setActiveTabs(key);
  };

  const handleApplyFilter = (objData) => {
    setApplyFilterObj(objData);
  };
  const showFilterDrawer = () => {
    setDrawerOpen((prevDrawerOpen) => !prevDrawerOpen);
    setIsGlobalFilter(!isGlobalFilter);
  };

  const handleTableSortColumn = (data) => {
    dispatch(
      setTableSortingData({
        sortColumn: data.sortColumn ? data.sortColumn : "customer_PO_Date",
        orderBy: data.orderBy,
        currentPage: data.currentPage,
      })
    );
  };
  function hasFilterDataExist(obj) {
    return Object.values(obj).some((array) => array.length > 0);
  }
  return (
    <Fragment>
      <div className="dashboard app-content content">
        <div className="content-wrapper">
          <div className="content-body">
            {hasFilterDataExist(applyFilterObj) && (
              <h5 className="appled_filter">Applied Filters</h5>
            )}
            <h5 class="content-header-title d-inline-block">Logistics</h5>
            <div className="main-setting">
              <Button
                className={`setting-button ${drawerOpen ? "open" : ""}`}
                icon={<SettingOutlined spin />}
                onClick={() => showFilterDrawer()}
              />
            </div>
            <Spin size="large" spinning={isCardsLoading}>
              <div class="row">
                <div class={cardClasses}>
                  <div class="card bg_info">
                    <div class="card-content">
                      <div class="card-body order images_bg">
                        <h4 class="text-white font24">
                          <img
                            src={SmallOrder}
                            className="card_small_img"
                            alt="Img"
                          />
                          <div style={{ display: "grid" }}>
                            <span style={{ fontSize: "22px" }}>
                              Total Orders
                            </span>
                            <label>1234</label>
                          </div>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div class={cardClasses}>
                  <div class="card bg_warning">
                    <div class="card-content">
                      <div class="card-body Materials images_bg">
                        <h4 class="text-white font24">
                          <img
                            src={SmallMaterial}
                            className="card_small_img"
                            alt="Img"
                          />
                          <div style={{ display: "grid" }}>
                            <span style={{ fontSize: "22px" }}>
                              In Production
                            </span>
                            <label>1111</label>
                          </div>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div class={cardClasses}>
                  <div class="card bg_danger">
                    <div class="card-content">
                      <div class="card-body Quantity images_bg ">
                        <h4
                          class="text-white font24"
                          style={{ textDecoration: "none" }}
                        >
                          <img
                            src={SmallQuantity}
                            className="card_small_img"
                            alt="Img"
                          />
                          <div style={{ display: "grid" }}>
                            <span style={{ fontSize: "22px" }}>
                              In Dispatch
                            </span>
                            <label>3333</label>
                          </div>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div class={cardClasses}>
                  <div class="card  bg_success">
                    <div class="card-content">
                      <div class="card-body MTQuantity images_bg">
                        <h4 class="text-white font24">
                          <img
                            src={SmallMTQ}
                            className="card_small_img"
                            alt="Img"
                          />
                          <div style={{ display: "grid" }}>
                            <span style={{ fontSize: "22px" }}>Delivered</span>
                            <label>2222</label>
                          </div>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Spin>
            <Card>
              <div className="row dashboard_tabs">
                <Tabs
                  activeKey={activeTabs}
                  className="dash_tab_panel"
                  type="card"
                  tabBarExtraContent={
                    <div className="tab_searchtxt">
                      <Search
                        placeholder="Search..."
                        className="dash_search"
                        allowClear
                        onSearch={handleOnSearchData}
                        size="large"
                        value={tableSearchData}
                        onChange={handleTblSearchChange}
                      />
                    </div>
                  }
                  onChange={handleTabChange}
                >
                  <TabPane tab="All" key="1">
                    <AllTab
                      tabTableData={tabTableData}
                      isLoading={isLoading}
                      OnhandleTableSortColumn={handleTableSortColumn}
                    />
                  </TabPane>
                  <TabPane tab="PO" key="2">
                    <POTab
                      tabTableData={tabTableData}
                      isLoading={isLoading}
                      OnhandleTableSortColumn={handleTableSortColumn}
                    />
                  </TabPane>
                  <TabPane tab="Material" key="3">
                    <MaterialTab
                      tabTableData={tabTableData}
                      isLoading={isLoading}
                      OnhandleTableSortColumn={handleTableSortColumn}
                    />
                  </TabPane>
                  <TabPane tab="Container/Truck" key="4">
                    <ContainerTab
                      tabTableData={tabTableData}
                      isLoading={isLoading}
                      OnhandleTableSortColumn={handleTableSortColumn}
                    />
                  </TabPane>
                </Tabs>
              </div>
            </Card>
          </div>
        </div>
      </div>

      <div className="main-filter">
        <GlobalFilterDrawer
          isGlobalFilter={isGlobalFilter}
          showFilterDrawer={showFilterDrawer}
          isEmployee={isEmployee}
          applyFilter={handleApplyFilter}
        />
      </div>
    </Fragment>
  );
};

export default LogisticDashboard;
