import { SET_COMPANYDETAILS, SET_COMPANYNULL } from "./CompanyCodeTypes";

export const setCompanyDetails = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_COMPANYDETAILS,
      payload: data
    });
  } catch (err) {}
};

export const seCompanyNull = () => async (dispatch) => {
  try {
    dispatch({
      type: SET_COMPANYNULL,
      payload: undefined
    });
  } catch (err) {}
};
