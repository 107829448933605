import React, { useEffect, useRef } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  Outlet,
  useParams,
} from "react-router-dom";
import { NotificationContainer } from "react-notifications";
import { useSelector } from "react-redux";

import O2WDashboard from "./pages/Elite/O2W/O2WDashboard";
import TabViewDetails from "../src/components/TabViewDetails";
import Login from "./pages/Login/Login";
import Layout from "./pages/main/layout";
import EliteCard from "./pages/Elite/EliteCard";
import EliteDashboard from "./pages/Elite/EliteDashboard";
import DetailsView from "./components/DetailsView";
import PaymentDashboard from "./pages/Elite/Payment/paymentDashboard";
import useDebouncedResizeObserver from "./hooks/useDebouncedResizeObserver";
import logisticDashboard from "./pages/Elite/Logistics/logisticDashboard";

const PrivateRoute = () => {
  const loginDetails = useSelector((state) => state.loginReducer);
  const isLoginSucceeded =
    loginDetails?.loginDetials?.userDetails?.isLoginSucceeded || false;

  return isLoginSucceeded ? (
    <Layout>
      <Outlet />
    </Layout>
  ) : (
    <Navigate to="/" />
  );
};

const RouteWithProps = ({ component: Component, ...rest }) => {
  const params = useParams();
  return <Component {...params} {...rest} />;
};
const ExternalRedirect = ({ url }) => {
  useEffect(() => {
    window.location.href = url;
  }, [url]);
  return null;
};

const App = () => {
  const appRef = useRef(null);

  const handleResize = (entries) => {};

  const { observe, unobserve } = useDebouncedResizeObserver(handleResize);

  useEffect(() => {
    if (appRef.current) {
      observe(appRef.current);
    }
    return () => {
      if (appRef.current) {
        unobserve(appRef.current);
      }
    };
  }, [observe, unobserve]);
  return (
    <div ref={appRef}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route element={<PrivateRoute />}>
            <Route path="/galaxyHome" element={<EliteCard />} />
            <Route path="/eliteDashboard" element={<EliteDashboard />} />
            <Route
              path="/dashboard/:type?"
              element={<RouteWithProps component={O2WDashboard} />}
            />
            <Route
              path="/:module/:detailsTab/:type/:id?"
              element={<RouteWithProps component={TabViewDetails} />}
            />
            <Route
              path="/detailsView/:type/:id?"
              element={<RouteWithProps component={DetailsView} />}
            />
            <Route
              path="/payment/:type?"
              element={<RouteWithProps component={PaymentDashboard} />}
            />
            <Route
              path="/logistic/:type"
              element={<RouteWithProps component={logisticDashboard} />}
            />
          </Route>
          <Route
            path="/admin"
            element={
              <ExternalRedirect url="https://knack-admin.brainvire.net/Login" />
            }
          />
        </Routes>
        <NotificationContainer />
      </BrowserRouter>
    </div>
  );
};

export default App;
