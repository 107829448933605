import React, { Fragment, useEffect, useState, useRef } from "react";
import { Table, Spin, Modal, Button, Select, Skeleton } from "antd";
import {
  Shipment,
  Documents,
  DeliveryTrack,
  Delivery,
  Confirm,
  Check,
  Anchor,
  BLDocument,
  imgDelete,
  imgDownload,
} from "../../pages/Elite/imageAssets";
import { NotificationManager } from "react-notifications";
import { FullscreenOutlined, UploadOutlined } from "@ant-design/icons";
import overTimeScheduleService from "../../services/OverTimeSchedule-tabs-service";
import { useSelector } from "react-redux";
import moment from "moment";

const { Option } = Select;

const uploadDocumentTypes = [
  {
    name: "Self Invoice",
    value: "Self Invoice",
  },
  {
    name: "Packing List",
    value: "Packing List",
  },
  {
    name: "Bill of Loading",
    value: "Bill of Loading",
  },
  {
    name: "COO",
    value: "COO",
  },
  {
    name: "COA",
    value: "COA",
  },
  {
    name: "Insurance",
    value: "Insurance",
  },
  {
    name: "Fumigation",
    value: "Fumigation",
  },
  {
    name: "Certificate",
    value: "Certificate",
  },
];

const TimelineTab = ({ labelId, tabType, dropdownValue, SapOrderNumber }) => {
  const fileInputRef = useRef(null);
  const contentRef = useRef(null);
  const supportedFileTypes = [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ]; // PDF, DOC, DOCX

  const userDetails = useSelector(
    (state) => state.loginReducer.loginDetials.userDetails
  );

  const [isLoading, setIsLoading] = useState(false);
  const [tblDeliveryData, setTblDeliveryData] = useState([]);
  const [tblEtaPortData, setTblEtaPortData] = useState([]);
  const [tblBlDocData, setTblBlDocData] = useState([]);
  const [tblMaterialDispatchData, setTblMaterialDispatchData] = useState([]);
  const [tblShippingBookingData, setTblShippingBookingData] = useState([]);
  const [tblDocumentsData, setTblDocumentsData] = useState([]);
  const [orderStatusDetails, setOrderStatusDetails] = useState({});
  const [orderPlacedDetails, setOrderPlacedDetails] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [isUploadDocModal, setIsUploadDocModal] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [invoiceTypeData, setInvoiceTypeData] = useState([]);
  const [selectedInvoiceType, setSelectedInvoiceType] = useState(null);
  const [selectedDocumentType, setSelectedDocumentType] = useState(null);
  const [isInvoiceTypeError, setIsInvoiceTypeError] = useState(false);
  const [isDocTypeError, setIsDocTypeError] = useState(false);
  const [isFileuploadError, setIsFileuploadError] = useState(false);
  const [timeLineDates, setTimeLineDates] = useState({});

  useEffect(() => {
    getOverviewGetOrderandInvoiceData();
  }, [labelId, tabType, dropdownValue]);

  useEffect(() => {
    const contentElement = contentRef.current;
    const handleImageClick = (event) => {
      if (
        event.target.tagName === "IMG" &&
        event.target.id.startsWith("download_doc_")
      ) {
        const fileURL = event.target.id?.split("_")[2];
        const row = { fileURL };
        handleDownloadDocument(row);
      }
    };

    if (contentElement) {
      contentElement.addEventListener("click", handleImageClick);
    }
    return () => {
      if (contentElement) {
        contentElement.removeEventListener("click", handleImageClick);
      }
    };
  }, [modalContent]);

  const getOverviewGetOrderandInvoiceData = () => {
    setIsLoading(true);
    let inputData = {
      dropdownValue,
      labelValue: labelId,
      flag: tabType,
      SapOrderNumber,
    };
    overTimeScheduleService
      .getTimelineResultsDataAPI(inputData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finalData = response.data.responseData;
          let tempInvoiceData = [];
          setOrderStatusDetails(finalData.orderSummaries);
          setTblDeliveryData(finalData.deliveryStatusDetails);
          setTblEtaPortData(finalData.etaDetails);
          setTblBlDocData(finalData.blDocDetails);
          setTblMaterialDispatchData(finalData.materialDispatchDetails);
          setTblShippingBookingData(finalData.shippingBookingDetails);
          setTblDocumentsData(removeNullData(finalData.invoiceDocumentList));
          tempInvoiceData = removeNullData(finalData.invoiceDocumentList).map(
            (item) => ({
              invoice_Number: item.invoice_Number,
              invoice_id: item.invoice_id,
            })
          );
          setInvoiceTypeData(tempInvoiceData);
          setOrderPlacedDetails(finalData.knackOrderDetails[0]);
          setTimeLineDates(finalData.timeLineDate);
          setIsLoading(false);
        } else {
          setTblDeliveryData([]);
          setTblEtaPortData([]);
          setTblMaterialDispatchData([]);
          setTblShippingBookingData([]);
          setTblDocumentsData([]);
          setOrderPlacedDetails({});
          setTimeLineDates({});
          NotificationManager.error(response.data.message);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error, "Error");
      });
  };

  function removeNullData(inputData) {
    return inputData.filter(
      (item) => item.invoice_Number !== null || item.invoice_id !== null
    );
  }

  const checkUploadFormValidation = () => {
    let isValidation;
    if (selectedInvoiceType === null) {
      setIsInvoiceTypeError(true);
      isValidation = true;
    }
    if (selectedDocumentType === null) {
      setIsDocTypeError(true);
      isValidation = true;
    }
    if (selectedFile === null) {
      setIsFileuploadError(true);
      isValidation = true;
    }
    return isValidation;
  };
  const handleSubmitDocUpload = () => {
    if (!checkUploadFormValidation()) {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("InvoiceId", selectedInvoiceType);
      formData.append("DocumentType", selectedDocumentType);
      formData.append("File", selectedFile);

      overTimeScheduleService
        .timelineUploadInvoiceDocAPI(formData)
        .then((response) => {
          if (response.data.responseData !== "Something went Wrong") {
            setIsLoading(false);
            setSelectedFile(null);
            getOverviewGetOrderandInvoiceData();
            NotificationManager.success(response.data.responseData);
            handleCloseUploadModal();
          } else {
            NotificationManager.error(response.data.responseData);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error, "Error");
        });
    }
  };
  const handleDownloadDocument = (rowData) => {
    const url = rowData.fileURL;
    window.open(url, "_blank");
  };

  const handleModalOpen = (body) => {
    let cardBodyContent = document.querySelector(`#${body}`).innerHTML;
    const container = document.createElement("div");

    container.innerHTML = cardBodyContent;

    const uploadBtn = container.querySelector("#upload_document_btn");
    const classRemove = container.querySelector(".documents_card_scroll");

    if (uploadBtn) {
      uploadBtn.parentNode.removeChild(uploadBtn);
    }
    if (classRemove) {
      classRemove.className = classRemove.className
        .replace("documents_card_scroll", "")
        .trim();
    }

    cardBodyContent = container.innerHTML;
    setModalContent(cardBodyContent);
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };
  const handleOpenUploadModal = () => {
    setIsUploadDocModal(true);
  };
  const handleCloseUploadModal = () => {
    setIsUploadDocModal(false);
    setSelectedFile(null);
    setSelectedDocumentType(null);
    setSelectedInvoiceType(null);
    setIsInvoiceTypeError(false);
    setIsDocTypeError(false);
    setIsFileuploadError(false);
  };

  const handleDocumentuploadChanges = (event) => {
    const maxFileSize = 3 * 1024 * 1024; // 3 MB in bytes
    let files = event.target.files[0];
    if (files && supportedFileTypes.includes(files.type)) {
      if (files.size <= maxFileSize) {
        setSelectedFile(files);
        setIsFileuploadError(false);
      } else {
        NotificationManager.error("File size should not exceed 3 MB.");
      }
    } else {
      NotificationManager.error("Please upload a PDF, DOC, or DOC file.");
    }
    event.target.value = null;
  };
  const fileUploadRefClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleFileRemove = () => {
    setSelectedFile(null);
    fileInputRef.current.value = null;
    setIsFileuploadError(false);
  };
  const handleSelectedType = (e, type) => {
    if (type === "invoice") {
      setSelectedInvoiceType(e);
      setIsInvoiceTypeError(false);
    } else {
      setSelectedDocumentType(e);
      setIsDocTypeError(false);
    }
  };
  return (
    <Fragment>
      <section class="timeline">
        <Spin size="large" spinning={isLoading}>
          <ul class="timeline-with-icons main-timeline">
            <li class="timeline-item  mb-5">
              <div className="timeline-icon bg-orange" title="Order Status">
                <span>
                  <img src={Check} className="timelineImage" alt="Img" />
                </span>
              </div>
              <div class="card">
                <div class="card-body p-3">
                  <div className="card-header">
                    <span class="orange fw-bold mb-2">Order Status</span>
                  </div>
                  <div className="p-2">
                    {isLoading ? (
                      <Skeleton.Button
                        size={"large"}
                        shape={"large"}
                        block={true}
                        active={isLoading}
                      />
                    ) : (
                      <>
                        <div className="mt-2">
                          <h6>
                            Order Quantity:
                            <span class="fw-bold px-2">
                              {orderStatusDetails?.totalQuantity ||
                              orderStatusDetails?.sale_Unit
                                ? orderStatusDetails?.totalQuantity +
                                  " " +
                                  orderStatusDetails?.sale_Unit
                                : "00"}
                            </span>
                          </h6>
                        </div>
                        <div>
                          <h6>
                            Deliver Quantity:
                            <span class="fw-bold  px-2">
                              {orderStatusDetails?.till_Date_Dispatched_Qty ||
                              orderStatusDetails?.sale_Unit
                                ? orderStatusDetails?.till_Date_Dispatched_Qty +
                                  " " +
                                  orderStatusDetails?.sale_Unit
                                : "00"}
                            </span>
                          </h6>
                        </div>
                        <div>
                          <h6>
                            Pending Quantity:
                            <span class="fw-bold  px-2">
                              {orderStatusDetails?.balance_Quantity ||
                              orderStatusDetails?.sale_Unit
                                ? orderStatusDetails?.balance_Quantity +
                                  " " +
                                  orderStatusDetails?.sale_Unit
                                : "00"}
                            </span>
                          </h6>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </li>
            <li class="timeline-item mb-5">
              {timeLineDates.deliveryStatusDetailsdate && (
                <div class="timeline-group event-date badge bg-warning p-2">
                  {timeLineDates.deliveryStatusDetailsdate}
                </div>
              )}
              <div className="timeline-icon bg-pink" title="Delivery">
                <span>
                  <img src={Delivery} className="timelineImage" alt="Img" />
                </span>
              </div>
              <div class="card">
                <div class="card-body p-3" id="DeliveryCard">
                  <div className="card-header">
                    <span class="pink fw-bold mb-2">Delivery</span>
                    <span
                      className="float-end hideIcon cur_point"
                      onClick={() => handleModalOpen("DeliveryCard")}
                    >
                      <FullscreenOutlined />
                    </span>
                  </div>
                  <div className="p-2" id="DeliveryCard">
                    <div class="row">
                      <Table
                        columns={[
                          {
                            title: "Sr No.",
                            dataIndex: "sr_No",
                            key: "number",
                            width: 100,
                            render: (_i, row, index) => {
                              return <div>{index + 1}</div>;
                            },
                          },
                          {
                            title: "Estimated Delivery Date",
                            dataIndex: "etA_Factory_Date",
                            width: 200,
                            render: (_i, row) => {
                              return (
                                <div>
                                  {row.etA_Factory_Date
                                    ? moment(row.etA_Factory_Date).format(
                                        "DD-MM-YYYY"
                                      )
                                    : "N/A"}
                                </div>
                              );
                            },
                          },
                          {
                            title: "Actual Delivery Date",
                            dataIndex: "delivery_Date",
                            key: "delivery_Date",
                            width: 200,
                            render: (_i, row) => {
                              return (
                                <div>
                                  {row.delivery_Date
                                    ? moment(row.delivery_Date).format(
                                        "DD-MM-YYYY"
                                      )
                                    : "N/A"}
                                </div>
                              );
                            },
                          },
                          {
                            title: "Delay",
                            dataIndex: "delivery_Status",
                            width: 200,
                            render: (_i, row) => {
                              return (
                                // <div
                                //   className={
                                //     row.delivery_Status
                                //       ?.split(":")[0]
                                //       .trim("") !== "Early"
                                //       ? "delay_status"
                                //       : "early_status"
                                //   }
                                // >
                                <div className="lbl_status">
                                  {/* <label
                                    className={
                                      row.delivery_Color === "red"
                                        ? "delay_red"
                                        : "delay_green"
                                    }
                                  > */}
                                  <label
                                    style={{
                                      backgroundColor:
                                        row.delivery_Color === "light green"
                                          ? "green"
                                          : row.delivery_Color,
                                    }}
                                  >
                                    {row.delivery_Status
                                      ? row.delivery_Status?.split(":")[0]
                                      : "N/A"}
                                    <p>
                                      {row.delivery_Status
                                        ? ":" +
                                          row.delivery_Status?.split(":")[1]
                                        : "N/A"}
                                    </p>
                                  </label>
                                </div>
                              );
                            },
                          },
                        ]}
                        dataSource={tblDeliveryData}
                        bordered
                        className="dashboard_tables"
                        scroll={{ x: 900 }}
                        pagination={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </li>

            <li class="timeline-item mb-5">
              {timeLineDates.etaDetailsdate && (
                <div class="timeline-group event-date badge bg-blue p-2">
                  {timeLineDates.etaDetailsdate}
                </div>
              )}
              <div className="timeline-icon bg-info" title="ETA Port">
                <span>
                  <img src={Anchor} className="tl_mater_Img" alt="Img" />
                </span>
              </div>
              <div class="card">
                <div class="card-body p-3" id="EtaCard">
                  <div className="card-header">
                    <span class="info fw-bold  mb-2">ETA Port</span>
                    <span
                      className="float-end hideIcon cur_point"
                      onClick={() => handleModalOpen("EtaCard")}
                    >
                      <FullscreenOutlined />
                    </span>
                  </div>
                  <div className="p-2">
                    <div class="row">
                      <Table
                        columns={[
                          {
                            title: "Sr No.",
                            dataIndex: "sr_No",
                            key: "number",
                            width: 100,
                            render: (_i, row, index) => {
                              return <div>{index + 1}</div>;
                            },
                          },
                          {
                            title: "Estimated ETA Date",
                            dataIndex: "etA_Port_Date",
                            width: 200,
                            render: (_i, row) => {
                              return (
                                <div>
                                  {row.etA_Port_Date
                                    ? moment(row.etA_Port_Date).format(
                                        "DD-MM-YYYY"
                                      )
                                    : "N/A"}
                                </div>
                              );
                            },
                          },
                          {
                            title: "Actual ETA Date",
                            dataIndex: "estimated_Time_of_Arrival",
                            key: "estimated_Time_of_Arrival",
                            width: 200,
                            render: (_i, row) => {
                              return (
                                <div>
                                  {row.estimated_Time_of_Arrival
                                    ? moment(
                                        row.estimated_Time_of_Arrival
                                      ).format("DD-MM-YYYY")
                                    : "N/A"}
                                </div>
                              );
                            },
                          },
                          {
                            title: "Delay",
                            dataIndex: "delivery_Status",
                            width: 200,
                            render: (_i, row) => {
                              return (
                                // <div
                                //   className={
                                //     row.delivery_Status
                                //       ?.split(":")[0]
                                //       .trim("") !== "Early"
                                //       ? "delay_status"
                                //       : "early_status"
                                //   }
                                // >
                                //   <label
                                //     className={
                                //       row.delivery_Color === "red"
                                //         ? "delay_red"
                                //         : "delay_green"
                                //     }
                                //   >
                                <div className="lbl_status">
                                  <label
                                    style={{
                                      backgroundColor:
                                        row.delivery_Color === "light green"
                                          ? "green"
                                          : row.delivery_Color,
                                    }}
                                  >
                                    {row.delivery_Status
                                      ? row.delivery_Status?.split(":")[0]
                                      : "N/A"}
                                    <p>
                                      {row.delivery_Status
                                        ? ":" +
                                          row.delivery_Status?.split(":")[1]
                                        : "N/A"}
                                    </p>
                                  </label>
                                </div>
                              );
                            },
                          },
                        ]}
                        dataSource={tblEtaPortData}
                        bordered
                        className="dashboard_tables"
                        scroll={{ x: 900 }}
                        pagination={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </li>

            <li class="timeline-item mb-5">
              {timeLineDates.invoiceDocumentDate && (
                <div class="timeline-group event-date badge bg-pink p-2">
                  {timeLineDates.invoiceDocumentDate}
                </div>
              )}
              <div className="timeline-icon bg-green" title="Documents">
                <span>
                  <img src={Documents} className="timelineImage" alt="Img" />
                </span>
              </div>
              <div class="card">
                <div class="card-body p-3" id="DocumentsCard">
                  <div>
                    <div className="card-header">
                      <span class="green fw-bold mb-2">Documents</span>
                      <span
                        className="float-end hideIcon cur_point"
                        onClick={() => handleModalOpen("DocumentsCard")}
                      >
                        <FullscreenOutlined />
                      </span>
                      {userDetails.isEmployee && (
                        <Button
                          id="upload_document_btn"
                          className="upload_docts"
                          type="primary"
                          shape="round"
                          icon={<UploadOutlined />}
                          disabled={
                            tblMaterialDispatchData
                              .filter((item) => item.invoice_Number)
                              .map((item) => item.invoice_Number?.length)
                              ?.length > 0
                              ? false
                              : true
                          }
                          onClick={handleOpenUploadModal}
                        >
                          Upload Doc
                        </Button>
                      )}
                      <div className="dis_flex">
                        <div class="text-light bg-dark text-center lbl_pad">
                          Documents for
                          {tblDocumentsData &&
                            " " +
                              tblDocumentsData?.filter(
                                (item) => item.documentList.length > 0
                              ).length +
                              " "}
                          Dispatches
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="documents_card_scroll">
                    <div className="p-2">
                      {tblDocumentsData &&
                        tblDocumentsData.map((item, i) => {
                          return (
                            <div key={i}>
                              {item.documentList.length > 0 && (
                                <div className="row margin_card">
                                  <div
                                    className={
                                      i % 2 === 0
                                        ? "border border-warning px-0"
                                        : "border border-primary px-0"
                                    }
                                  >
                                    <h6
                                      className={
                                        i % 2 === 0
                                          ? "p-1 bg-orange"
                                          : "p-1 bg-blue"
                                      }
                                    >
                                      Invoice #{item.invoice_Number}
                                    </h6>
                                    <Table
                                      columns={[
                                        {
                                          title: "Sr No.",
                                          dataIndex: "sr_No",
                                          key: "number",
                                          width: 80,
                                          render: (_i, row, index) => {
                                            return <div>{index + 1}</div>;
                                          },
                                        },
                                        {
                                          title: "Document Type",
                                          dataIndex: "document_Type",
                                          key: "document_Type",
                                          width: 200,
                                        },
                                        {
                                          title: "Document Name",
                                          dataIndex: "file_Name",
                                          key: "file_Name",
                                          width: 200,
                                        },
                                        {
                                          title: "Download",
                                          dataIndex: "id",
                                          key: "id",
                                          className: "download_link_col",
                                          width: 150,
                                          render: (_i, row) => {
                                            return (
                                              <div className="donw_link">
                                                <img
                                                  src={imgDownload}
                                                  alt="img"
                                                  id={`download_doc_${row.fileURL}`}
                                                  onClick={() =>
                                                    handleDownloadDocument(row)
                                                  }
                                                />
                                              </div>
                                            );
                                          },
                                        },
                                      ]}
                                      bordered
                                      dataSource={item.documentList}
                                      className="dashboard_tables"
                                      scroll={{ x: 900 }}
                                      pagination={false}
                                      locale={{
                                        emptyText:
                                          "Please Upload your invoice documents",
                                      }}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </li>

            <li class="timeline-item mb-5">
              {timeLineDates.blDocDetailsdate && (
                <div class="timeline-group event-date badge bg-warning p-2">
                  {timeLineDates.blDocDetailsdate}
                </div>
              )}
              <div className="timeline-icon bg-purple" title="BL Documents">
                <span>
                  <img src={BLDocument} className="timelineImage" alt="Img" />
                </span>
              </div>
              <div class="card">
                <div class="card-body p-3" id="BLDocumentsCard">
                  <div className="card-header">
                    <span class="purple fw-bold mb-2">BL Documents</span>
                    <span
                      className="float-end hideIcon cur_point"
                      onClick={() => handleModalOpen("BLDocumentsCard")}
                    >
                      <FullscreenOutlined />
                    </span>
                  </div>
                  <div className="p-2">
                    <div class="row">
                      <Table
                        columns={[
                          {
                            title: "Sr No.",
                            dataIndex: "sr_No",
                            key: "number",
                            width: 100,
                            render: (_i, row, index) => {
                              return <div>{index + 1}</div>;
                            },
                          },
                          {
                            title: "Shipping Line Name",
                            dataIndex: "shippingLine_Name",
                            key: "shippingLine_Name",
                            render: (_i, row) => (
                              <span>
                                {row.shippingLine_Name
                                  ? row.shippingLine_Name
                                  : "N/A"}
                              </span>
                            ),
                          },
                          {
                            title: "BL #",
                            dataIndex: "bL_Number",
                            key: "bL_Number",
                            width: 180,
                            render: (_i, row) => {
                              return (
                                <div className="bL_status_color">
                                  <label>{row.bL_Number}</label>
                                </div>
                              );
                            },
                          },
                          {
                            title: "Estimated BL Date",
                            dataIndex: "bL_Document_Generation_Date",
                            width: 200,
                            render: (_i, row) => {
                              return (
                                <div>
                                  {row.bL_Document_Generation_Date
                                    ? moment(
                                        row.bL_Document_Generation_Date
                                      ).format("DD-MM-YYYY")
                                    : "N/A"}
                                </div>
                              );
                            },
                          },
                          {
                            title: "Actual BL Date",
                            dataIndex: "bL_Date",
                            key: "bL_Date",
                            width: 200,
                            render: (_i, row) => {
                              return (
                                <div>
                                  {row.bL_Date
                                    ? moment(row.bL_Date).format("DD-MM-YYYY")
                                    : "N/A"}
                                </div>
                              );
                            },
                          },
                          {
                            title: "Delay",
                            dataIndex: "delivery_Status",
                            width: 200,
                            render: (_i, row) => {
                              return (
                                // <div
                                //   className={
                                //     row.delivery_Status
                                //       ?.split(":")[0]
                                //       .trim("") !== "Early"
                                //       ? "delay_status"
                                //       : "early_status"
                                //   }
                                // >
                                //   <label
                                //     className={
                                //       row.delivery_Color === "red"
                                //         ? "delay_red"
                                //         : "delay_green"
                                //     }
                                //   >
                                <div className="lbl_status">
                                  <label
                                    style={{
                                      backgroundColor:
                                        row.delivery_Color === "light green"
                                          ? "green"
                                          : row.delivery_Color,
                                    }}
                                  >
                                    {row.delivery_Status
                                      ? row.delivery_Status?.split(":")[0]
                                      : "N/A"}
                                    <p>
                                      {row.delivery_Status
                                        ? ":" +
                                          row.delivery_Status?.split(":")[1]
                                        : "N/A"}
                                    </p>
                                  </label>
                                </div>
                              );
                            },
                          },
                        ]}
                        dataSource={tblBlDocData}
                        bordered
                        className="dashboard_tables"
                        scroll={{ x: 900 }}
                        pagination={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="timeline-item mb-5">
              {timeLineDates.materialDispatchDetailsdate && (
                <div class="timeline-group event-date badge bg-blue p-2">
                  {timeLineDates.materialDispatchDetailsdate}
                </div>
              )}
              <div
                className="timeline-icon bg-orange"
                title="Material Dispatch"
              >
                <span>
                  <img src={DeliveryTrack} className="tl_mater_Img" alt="Img" />
                </span>
              </div>
              <div class="card">
                <div class="card-body p-3" id="MaterialDispatchCard">
                  <div className="card-header">
                    <span class="orange fw-bold  mb-2">Material Dispatch</span>
                    <span
                      className="float-end hideIcon cur_point"
                      onClick={() => handleModalOpen("MaterialDispatchCard")}
                    >
                      <FullscreenOutlined />
                    </span>
                    <div>Total {tblMaterialDispatchData.length} Invoices</div>
                  </div>
                  <div className="p-2">
                    <div class="row">
                      <Table
                        columns={[
                          {
                            title: "Sr No.",
                            dataIndex: "sr_No",
                            key: "number",
                            width: 90,
                            render: (_i, row, index) => {
                              return <div>{index + 1}</div>;
                            },
                          },
                          {
                            title: "Invoices",
                            dataIndex: "invoice_Number",
                            key: "invoice_Number",
                            width: 180,
                            render: (_i, row) => {
                              return (
                                <div className="mate_invoice_color">
                                  <label>
                                    {row.invoice_Number
                                      ? row.invoice_Number
                                      : "N/A"}
                                  </label>
                                </div>
                              );
                            },
                          },
                          {
                            title: "Container",
                            dataIndex: "container_Type",
                            key: "container_Type",
                            width: 180,
                            render: (_i, row) => {
                              return (
                                <div className="bL_status_color">
                                  <label>
                                    {row.container_Type
                                      ? row.container_Type
                                      : "N/A"}
                                  </label>
                                </div>
                              );
                            },
                          },
                          {
                            title: "Estimated Dispatch Date",
                            dataIndex: "estimated_Plan_Date",
                            width: 200,
                            render: (_i, row) => {
                              return (
                                <div>
                                  {row.estimated_Plan_Date
                                    ? moment(row.estimated_Plan_Date).format(
                                        "DD-MM-YYYY"
                                      )
                                    : "N/A"}
                                </div>
                              );
                            },
                          },
                          {
                            title: "Actual Dispatch Date",
                            dataIndex: "invoice_Date",
                            key: "invoice_Date",
                            width: 200,
                            render: (_i, row) => {
                              return (
                                <div>
                                  {row.invoice_Date
                                    ? moment(row.invoice_Date).format(
                                        "DD-MM-YYYY"
                                      )
                                    : "N/A"}
                                </div>
                              );
                            },
                          },
                          {
                            title: "Material",
                            dataIndex: "material_Name",
                            key: "material_Name",
                            width: 200,
                          },
                          {
                            title: "Quantity",
                            dataIndex: "sale_Quantity",
                            key: "sale_Quantity",
                            width: 200,
                            render: (_i, row) => {
                              return (
                                <div>
                                  {row.sale_Quantity ? row.sale_Quantity + " " + row?.sale_Unit : "0"}
                                </div>
                              );
                            },
                          },
                          {
                            title: "Delay",
                            dataIndex: "delivery_Status",
                            width: 200,
                            render: (_i, row) => {
                              return (
                                // <div
                                //   className={
                                //     row.delivery_Status
                                //       ?.split(":")[0]
                                //       .trim("") !== "Early"
                                //       ? "delay_status"
                                //       : "early_status"
                                //   }
                                // >
                                //   <label
                                //     className={
                                //       row.delivery_Color === "red"
                                //         ? "delay_red"
                                //         : "delay_green"
                                //     }
                                //   >
                                <div className="lbl_status">
                                  <label
                                    style={{
                                      backgroundColor:
                                        row.delivery_Color === "light green"
                                          ? "green"
                                          : row.delivery_Color,
                                    }}
                                  >
                                    {row.delivery_Status
                                      ? row.delivery_Status?.split(":")[0]
                                      : "N/A"}
                                    <p>
                                      {row.delivery_Status
                                        ? ":" +
                                          row.delivery_Status?.split(":")[1]
                                        : "N/A"}
                                    </p>
                                  </label>
                                </div>
                              );
                            },
                          },
                        ]}
                        dataSource={tblMaterialDispatchData}
                        bordered
                        className="dashboard_tables"
                        scroll={{ x: 900 }}
                        pagination={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="timeline-item mb-5">
              {timeLineDates.shippingBookingDetailsdate && (
                <div class="timeline-group event-date badge bg-pink p-2">
                  {timeLineDates.shippingBookingDetailsdate}
                </div>
              )}
              <div className="timeline-icon bg-info" title="Shipment Booking">
                <span>
                  <img src={Shipment} className="tl_mater_Img" alt="Img" />
                </span>
              </div>
              <div class="card">
                <div class="card-body p-3" id="ShipmentBookingCard">
                  <div className="card-header">
                    <span class="info fw-bold  mb-2">Shipment Booking</span>
                    <span
                      className="float-end hideIcon cur_point"
                      onClick={() => handleModalOpen("ShipmentBookingCard")}
                    >
                      <FullscreenOutlined />
                    </span>
                  </div>
                  <div className="p-2">
                    <div class="row">
                      <Table
                        columns={[
                          {
                            title: "Sr No.",
                            dataIndex: "sr_No",
                            key: "number",
                            width: 100,
                            render: (_i, row, index) => {
                              return <div>{index + 1}</div>;
                            },
                          },
                          {
                            title: "Shipment Booking Date",
                            dataIndex: "shipment_Booking_Date",
                            key: "shipment_Booking_Date",
                            width: 200,
                            render: (_i, row) => {
                              return (
                                <div>
                                  {row.shipment_Booking_Date
                                    ? moment(row.shipment_Booking_Date).format(
                                        "DD-MM-YYYY"
                                      )
                                    : "N/A"}
                                </div>
                              );
                            },
                          },
                          {
                            title: "Actual Booking Date",
                            dataIndex: "proposed_Shipment_Booking_Date",
                            key: "proposed_Shipment_Booking_Date",
                            width: 200,
                            render: (_i, row) => {
                              return (
                                <div>
                                  {row.proposed_Shipment_Booking_Date
                                    ? moment(
                                        row.proposed_Shipment_Booking_Date
                                      ).format("DD-MM-YYYY")
                                    : "N/A"}
                                </div>
                              );
                            },
                          },
                          {
                            title: "Delay",
                            dataIndex: "delivery_Status",
                            width: 200,
                            render: (_i, row) => {
                              return (
                                // <div
                                //   className={
                                //     row.delivery_Status
                                //       ?.split(":")[0]
                                //       .trim("") !== "Early"
                                //       ? "delay_status"
                                //       : "early_status"
                                //   }
                                // >
                                //   <label
                                //     className={
                                //       row.delivery_Color === "red"
                                //         ? "delay_red"
                                //         : "delay_green"
                                //     }
                                //   >
                                <div className="lbl_status">
                                  <label
                                    style={{
                                      backgroundColor:
                                        row.delivery_Color === "light green"
                                          ? "green"
                                          : row.delivery_Color,
                                    }}
                                  >
                                    {row.delivery_Status
                                      ? row.delivery_Status?.split(":")[0]
                                      : "N/A"}
                                    <p>
                                      {row.delivery_Status
                                        ? ":" +
                                          row.delivery_Status?.split(":")[1]
                                        : "N/A"}
                                    </p>
                                  </label>
                                </div>
                              );
                            },
                          },
                        ]}
                        dataSource={tblShippingBookingData}
                        bordered
                        className="dashboard_tables"
                        scroll={{ x: 900 }}
                        pagination={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="timeline-item mb-5">
              {orderPlacedDetails?.delivery_Status && (
                <div class="timeline-group event-date badge bg-success p-2">
                  {moment(orderPlacedDetails?.knack_Schedule_SO_Date).format(
                    "DD-MM-YYYY"
                  )}
                </div>
              )}
              <div
                className="timeline-icon bg-pink"
                title="Order placed In System"
              >
                <span>
                  <img src={Confirm} className="timelineImage" alt="Img" />
                </span>
              </div>
              <div class="card">
                <div class="card-body p-3" id="OrderplacedSystemCard">
                  <div className="card-header">
                    <span class="pink fw-bold  mb-2">
                      Order placed In System
                    </span>
                    <span
                      className="float-end hideIcon cur_point"
                      onClick={() => handleModalOpen("OrderplacedSystemCard")}
                    >
                      <FullscreenOutlined />
                    </span>
                  </div>
                  {isLoading ? (
                    <Skeleton.Button
                      size={"large"}
                      shape={"large"}
                      block={true}
                      active={isLoading}
                    />
                  ) : (
                    <div className="p-2">
                      <div>
                        <div>
                          PO Date :
                          <span class="fnt_w_bold">
                            {orderPlacedDetails?.customer_PO_Date
                              ? moment(
                                  orderPlacedDetails?.customer_PO_Date
                                ).format("DD-MM-YYYY")
                              : "N/A"}
                          </span>
                        </div>
                        <div>
                          Knack Order Date :
                          <span class="fnt_w_bold">
                            {orderPlacedDetails?.knack_Schedule_SO_Date
                              ? moment(
                                  orderPlacedDetails?.knack_Schedule_SO_Date
                                ).format("DD-MM-YYYY")
                              : "N/A"}
                          </span>
                          <span
                            class={
                              orderPlacedDetails?.delivery_Status?.split(
                                ":"
                              )[0] === "Early"
                                ? "knack_delay_status early_green"
                                : "knack_delay_status delay_red"
                            }
                          >
                            {orderPlacedDetails?.delivery_Status
                              ? orderPlacedDetails?.delivery_Status?.split(
                                  ":"
                                )[0]
                              : "N/A"}
                            <p>
                              {orderPlacedDetails?.delivery_Status
                                ? ":" +
                                  orderPlacedDetails?.delivery_Status?.split(
                                    ":"
                                  )[1]
                                : "N/A"}
                            </p>
                          </span>
                        </div>
                        <hr />
                        <div className="lbl_flex">
                          PO:
                          <p>{orderPlacedDetails?.customer_PO_Number}</p>
                        </div>
                        {/* <div className="lbl_flex">
                        Scheduled: <p>#10</p>
                      </div> */}
                        <div className="lbl_flex">
                          Material: <p>{orderPlacedDetails?.material_Name}</p>
                        </div>
                        <div className="lbl_flex">
                          Quantity:
                          <p>
                            {orderPlacedDetails?.sale_Quantity ||
                              (orderPlacedDetails?.sale_Unit &&
                                orderPlacedDetails?.sale_Quantity +
                                  " " +
                                  orderPlacedDetails?.sale_Unit)}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </li>
          </ul>
          <Modal
            title="Upload Document"
            className="upload_documents"
            open={isUploadDocModal}
            onCancel={handleCloseUploadModal}
            footer={null}
          >
            <div>
              <div className="row upload_doc_mdl">
                <div className="col-lg-6 col-12">
                  <div className="upload_doc_select">
                    <label>Select Invoice No:</label>
                    <Select
                      placeholder="Please Invoice"
                      onChange={(e) => handleSelectedType(e, "invoice")}
                      value={selectedInvoiceType}
                      className="custom_placeholder"
                    >
                      {invoiceTypeData.map((item, i) => {
                        return (
                          <Option key={i} value={item.invoice_id}>
                            {item.invoice_Number}
                          </Option>
                        );
                      })}
                    </Select>
                    {isInvoiceTypeError && (
                      <p className="form_error">Invoice No is Required!</p>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 col-12" style={{ paddingTop: "10px" }}>
                  <div className="upload_doc_select">
                    <label>Select Document Type:</label>
                    <Select
                      placeholder="Please select"
                      onChange={(e) => handleSelectedType(e, "document")}
                      value={selectedDocumentType}
                      className="custom_placeholder"
                    >
                      {uploadDocumentTypes.map((item, d) => {
                        return (
                          <Option key={d} value={item.value}>
                            {item.name}
                          </Option>
                        );
                      })}
                    </Select>
                    {isDocTypeError && (
                      <p className="form_error">Document Type is Required!</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div class="drop_box">
                    <header>
                      <h4>Select File here</h4>
                    </header>
                    <p>Files Supported: PDF, PNG, DOC</p>
                    <input
                      type="file"
                      ref={fileInputRef}
                      accept=".doc,.docx,.pdf"
                      style={{ display: "none" }}
                      onChange={handleDocumentuploadChanges}
                    />
                    <Button
                      className="file_upload_btn"
                      onClick={fileUploadRefClick}
                      icon={<UploadOutlined />}
                    >
                      Choose File
                    </Button>
                    {selectedFile && (
                      <div className="file_info">
                        <span title={selectedFile.name}>
                          {selectedFile.name.length > 35
                            ? selectedFile.name.substr(0, 35).concat("...")
                            : selectedFile.name}
                        </span>
                        <img
                          src={imgDelete}
                          alt="img"
                          onClick={handleFileRemove}
                        />
                      </div>
                    )}
                  </div>
                  {isFileuploadError && (
                    <p className="form_error">Please Select File!</p>
                  )}
                </div>
              </div>
              <div className="upload_btn">
                <Button className="file_submit" onClick={handleSubmitDocUpload}>
                  Submit
                </Button>
                <Button
                  className="file_cancel"
                  onClick={handleCloseUploadModal}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Modal>
          {/* full screen show card */}
          <Modal
            visible={modalVisible}
            onCancel={handleModalClose}
            footer={null}
            centered
            width={"100%"}
            style={{ top: 0, maxWidth: "100%" }}
            bodyStyle={{ height: "100vh", width: "100%", overflow: "auto" }}
          >
            <div
              ref={contentRef}
              dangerouslySetInnerHTML={{ __html: modalContent }}
            ></div>
          </Modal>
        </Spin>
      </section>
    </Fragment>
  );
};

export default TimelineTab;
