import React, { Fragment, useState, useEffect } from "react";
import { Card, Tabs, Button, Select } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import GlobalFilterDrawer from "../../../components/GlobalFilterDrawer";
import { checkPaymentActiveTabNo } from "../../../helper/commonData";
import CustomerTab from "./PaymentTabs/CustomerTab";
import CustomerOutstandingTbl from "./PaymentTabs/CustomerOutstandingTbl";
import CustomerAgingTbl from "./PaymentTabs/CustomerAgingTbl";
import InvoiceDetailsTbl from "./PaymentTabs/InvoiceDetailsTbl";
import { imgBank, imgFilter } from "../imageAssets";
import paymentService from "../../../services/Payment/payment-service";
import eliteCardService from "../../../services/elite-cards-service";

const { TabPane } = Tabs;
const { Option } = Select;

const PaymentDashboard = ({ type }) => {
  const navigate = useNavigate();
  const companyDetails = useSelector(
    (state) => state.CompanyDetails.companyDetials
  );

  const userDetails = useSelector(
    (state) => state.loginReducer.loginDetials.userDetails
  );

  const [isLoading, setIsLoading] = useState(false);
  const [isGlobalFilter, setIsGlobalFilter] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [activeTabs, setActiveTabs] = useState("");
  const [isEmployee, setIsEmployee] = useState(false);
  const [applyFilterObj, setApplyFilterObj] = useState({});
  const [selectAnalysisType, setSelectAnalysisType] = useState("C");
  const [tabWiseTableData, setTabWiseTableData] = useState([]);
  const [totalAmountLCYCount, setTotalAmountLCYCount] = useState(0);
  const [tableObjColumn, setTableObjColumn] = useState({
    currentPage: "",
  });

  useEffect(() => {
    setIsEmployee(userDetails.isEmployee);
    handleGetPaymentDashboardCount();
  }, []);
  useEffect(() => {
    if (tableObjColumn.currentPage !== "") {
      handleGetPaymentTableData();
    }
  }, [tableObjColumn]);

  useEffect(() => {
    let tabKey = checkPaymentActiveTabNo(type);
    setActiveTabs(tabKey);
  }, [type]);

  const handleApplyFilter = (objData) => {
    setApplyFilterObj(objData);
  };
  const showFilterDrawer = () => {
    setDrawerOpen((prevDrawerOpen) => !prevDrawerOpen);
    setIsGlobalFilter(!isGlobalFilter);
  };
  //// ---------------API Calling Function code start
  /// get Dashboard listing API
  const handleGetPaymentTableData = () => {
    setIsLoading(true);
    let inputData = {
      company_Code: companyDetails ? companyDetails.company_Code : "",
      type: selectAnalysisType,
      search: "",
      pageindex:
        tableObjColumn.currentPage !== ""
          ? tableObjColumn.currentPage.toString()
          : "1",
      pagesize: "10",
    };

    const apiCall =
      activeTabs === "2"
        ? paymentService.getPaymentCustomerWisePendingDataAPI(inputData)
        : activeTabs === "3"
        ? paymentService.getPaymentCustomerAgeingDataAPI(inputData)
        : paymentService.getPaymentInvoiceDataAPI(inputData);

    apiCall
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finaldata = response.data.responseData;
          setTabWiseTableData(finaldata);
        } else {
          NotificationManager.error(response.data.message);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error, "Error");
      });
  };

  //// handle get dashboard counts API
  const handleGetPaymentDashboardCount = () => {
    let inputData = {
      company_Code: companyDetails ? companyDetails.company_Code : "",
      type: selectAnalysisType,
    };
    eliteCardService
      .getPaymentTileCountsAPI(inputData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          let objData = response.data.responseData;
          if (Object.keys(objData).length > 0) {
            setTotalAmountLCYCount(objData.totalPendingAmount_LCY);
          } else {
            setTotalAmountLCYCount(0);
          }
        } else {
          NotificationManager.error(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };
  //// ---------------API Calling Function code end

  /// Tab Onchange
  const handleTabChange = (key) => {
    let tabName = "";
    switch (key) {
      case "1":
        tabName = "customer";
        break;
      case "2":
        tabName = "customerOutstanding";
        break;
      case "3":
        tabName = "customerAging";
        break;
      case "4":
        tabName = "invoice";
        break;
      default:
        tabName = "customer";
        break;
    }
    setTableObjColumn({
      currentPage: "1",
    });
    navigate(`/payment/${tabName}`);
    setActiveTabs(key);
  };
  const handleAnalysisTypeChange = (e) => {
    setSelectAnalysisType(e);
    setTableObjColumn({
      currentPage: "1",
    });
  };
  const handleTableSortColumn = (data) => {
    setTableObjColumn({
      currentPage: data.currentPage,
    });
  };
  return (
    <Fragment>
      <div className="app-content content">
        <div className="content-wrapper">
          <div className="content-body">
            <h5 class="content-header-title d-inline-block">Payment</h5>
            <div className="main-setting">
              <Button
                className={`setting-button ${drawerOpen ? "open" : ""}`}
                icon={<SettingOutlined spin />}
                onClick={() => showFilterDrawer()}
              />
            </div>
            <div className="row payment_tab payment_card1">
              <div className="col-md-10 card_10">
                <div className="d-flex justify-content-between pb-30">
                  <label>Outstanding Analysis</label>
                  <span className="reset_filter_spn">
                    Reset filter
                    <img src={imgFilter} alt="img" className="pb_5" />
                  </span>
                </div>
                <div className="d-flex" style={{ overflow: "auto" }}>
                  <div className="mr_12">
                    <Select
                      mode="multiple"
                      optionFilterProp="children"
                      placeholder="Please select"
                      showSearch={false}
                      showArrow
                    >
                      <Option
                        key="all"
                        value="all"
                        // label={isSelectAll ? "Deselect All" : "Select All"}
                        label="Select All"
                      >
                        Select All
                      </Option>
                      <Option>Option 1</Option>
                      <Option>Option 2</Option>
                    </Select>
                  </div>
                  <div className="mr_12">
                    <Select
                      mode="multiple"
                      optionFilterProp="children"
                      placeholder="Please select"
                      showSearch={false}
                      showArrow
                    >
                      <Option
                        key="all"
                        value="all"
                        // label={isSelectAll ? "Deselect All" : "Select All"}
                        label="Select All"
                      >
                        Select All
                      </Option>
                      <Option>Option 1</Option>
                      <Option>Option 2</Option>
                    </Select>
                  </div>
                  <div className="mr_12">
                    <Select
                      mode="multiple"
                      optionFilterProp="children"
                      placeholder="Please select"
                      showSearch={false}
                      showArrow
                    >
                      <Option
                        key="all"
                        value="all"
                        // label={isSelectAll ? "Deselect All" : "Select All"}
                        label="Select All"
                      >
                        Select All
                      </Option>
                      <Option>Option 1</Option>
                      <Option>Option 2</Option>
                    </Select>
                  </div>
                  <div className="mr_12">
                    <Select
                      mode="multiple"
                      optionFilterProp="children"
                      placeholder="Please select"
                      showSearch={false}
                      showArrow
                    >
                      <Option
                        key="all"
                        value="all"
                        // label={isSelectAll ? "Deselect All" : "Select All"}
                        label="Select All"
                      >
                        Select All
                      </Option>
                      <Option>Option 1</Option>
                      <Option>Option 2</Option>
                    </Select>
                  </div>
                  <div>
                    <Select
                      mode="multiple"
                      optionFilterProp="children"
                      placeholder="Please select"
                      showSearch={false}
                      showArrow
                    >
                      <Option
                        key="all"
                        value="all"
                        // label={isSelectAll ? "Deselect All" : "Select All"}
                        label="Select All"
                      >
                        Select All
                      </Option>
                      <Option>Option 1</Option>
                      <Option>Option 2</Option>
                    </Select>
                  </div>
                </div>
              </div>
              <div className="col-md-2 card_2">
                <div className="left_section_card">
                  <img src={imgBank} alt="img" />
                  <label className="count">{totalAmountLCYCount}</label>
                  <span>Total Outstanding LCY</span>
                </div>
              </div>
            </div>
            <div className="pay_section_1">
              <div className="row payment_tab">
                <div className="col-md-4">
                  <label className="analy_type">Select Analysis Type</label>
                  <Select
                    placeholder="Please select"
                    value={selectAnalysisType}
                    onChange={handleAnalysisTypeChange}
                    optionFilterProp="children"
                  >
                    <Option value="C">Credit Days</Option>
                    <Option value="O">Overdue</Option>
                    <Option value="P">Planning Due Date</Option>
                    <Option value="T">Total Days</Option>
                  </Select>
                </div>
                <div className="col-md-8">
                  <Tabs
                    activeKey={activeTabs}
                    className="tab_panel"
                    type="card"
                    onChange={handleTabChange}
                  >
                    <TabPane tab="Customer" key="1" />
                    <TabPane tab="Customer wise outstanding" key="2" />
                    <TabPane tab="Customer Aging Details" key="3" />
                    <TabPane tab="Invoice Details" key="4" />
                  </Tabs>
                </div>
              </div>
            </div>
            <Card className="pay_section_2">
              <div className="row">
                <div className="col-md-12">
                  {activeTabs === "1" && (
                    <CustomerTab selectAnalysisType={selectAnalysisType} />
                  )}
                  {activeTabs === "2" && (
                    <CustomerOutstandingTbl
                      tabTableData={tabWiseTableData}
                      isLoading={isLoading}
                      OnhandleTableSortColumn={handleTableSortColumn}
                    />
                  )}
                  {activeTabs === "3" && (
                    <CustomerAgingTbl
                      tabTableData={tabWiseTableData}
                      isLoading={isLoading}
                      OnhandleTableSortColumn={handleTableSortColumn}
                    />
                  )}
                  {activeTabs === "4" && (
                    <InvoiceDetailsTbl
                      tabTableData={tabWiseTableData}
                      isLoading={isLoading}
                      OnhandleTableSortColumn={handleTableSortColumn}
                    />
                  )}
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>

      <div className="main-filter">
        <GlobalFilterDrawer
          isGlobalFilter={isGlobalFilter}
          showFilterDrawer={showFilterDrawer}
          isEmployee={isEmployee}
          applyFilter={handleApplyFilter}
        />
      </div>
    </Fragment>
  );
};

export default PaymentDashboard;
