import React, { Fragment, useEffect, useState } from "react";
import { Drawer, Button, Select, Space } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import EmployeeFilterService from "./../services/employee-filter-service";
import { useSelector } from "react-redux";

const { Option } = Select;

const GlobalFilterDrawer = ({
  isGlobalFilter,
  showFilterDrawer,
  isEmployee,
  applyFilter
}) => {
  const loginDetails = useSelector(
    (state) => state.loginReducer.loginDetials.userDetails
  );

  const [generalManagerData, setGeneralManagerData] = useState([]);
  const [regionalManagerData, setRegionalManagerData] = useState([]);
  const [areaManagerData, setAreaManagerData] = useState([]);
  const [groupNameData, setGroupNameData] = useState([]);
  const [companyNameData, setCompanyNameData] = useState([]);
  const [custGroupNameData, setCustGroupNameData] = useState([]);
  const [custOrganizationData, setCustOrganizationData] = useState([]);
  const [customerUsersData, setCustomerUsersData] = useState([]);
  const [selectedCustomerUser, setSelectedCustomerUser] = useState(null);
  const [empFilter, setEmpFilter] = useState({
    generalManager: [],
    regionalManager: [],
    areaManager: [],
    groupName: [],
    companyName: []
  });

  const [custFilter, setCustFilter] = useState({
    groupName: [],
    custOrganization: []
  });

  useEffect(() => {
    if (isGlobalFilter) {
      if (isEmployee) {
        switch (loginDetails.designation) {
          case "Director":
            handleGetEmpGeneralManagerData();
            break;
          case "General Manager":
            handleGetRegionalManagerData("");
            break;
          case "Regional Manager":
            handleGetAreaManagerData("");
            break;

          default:
            console.log(loginDetails.designation, "User Role");
            break;
        }
        // handleGetGroupNamesData("");
      } else {
        handleGetCustGroupNameData();
        handleGetCustomerUsersByOrganisationIdsData();
      }
    }
  }, [isGlobalFilter]);

  useEffect(() => {
    // handleGetGroupNamesData("");
  }, []);

  const handleEmpMultiSelectChange = (e, sapCode, name) => {
    let tempState = empFilter;
    tempState[name] = e;
    setEmpFilter((prevState) => ({
      ...prevState,
      ...tempState
    }));

    if (e.length > 0) {
      switch (name) {
        case "generalManager":
          setRegionalManagerData([]);
          setAreaManagerData([]);
          tempState["regionalManager"] = [];
          tempState["areaManager"] = [];
          setEmpFilter((prevState) => ({
            ...prevState,
            ...tempState
          }));
          handleGetRegionalManagerData(e);

          break;
        case "regionalManager":
          setAreaManagerData([]);
          tempState["areaManager"] = [];
          setEmpFilter((prevState) => ({
            ...prevState,
            ...tempState
          }));
          handleGetAreaManagerData(e);
          break;
        case "areaManager":
          break;
        case "groupName":
          setCompanyNameData([]);
          tempState["companyName"] = [];
          setEmpFilter((prevState) => ({
            ...prevState,
            ...tempState
          }));
          handleGetCompanyNamesData(e);
          break;
        default:
          break;
      }
    } else {
      switch (name) {
        case "generalManager":
          setAreaManagerData([]);
          setGroupNameData([]);
          setCompanyNameData([]);
          tempState["regionalManager"] = [];
          tempState["areaManager"] = [];
          tempState["groupName"] = [];
          tempState["companyName"] = [];
          setEmpFilter((prevState) => ({
            ...prevState,
            ...tempState
          }));
          break;
        case "regionalManager":
          setAreaManagerData([]);
          setGroupNameData([]);
          setCompanyNameData([]);
          tempState["regionalManager"] = [];
          tempState["areaManager"] = [];
          tempState["groupName"] = [];
          tempState["companyName"] = [];
          setEmpFilter((prevState) => ({
            ...prevState,
            ...tempState
          }));
          break;
        case "areaManager":
          setGroupNameData([]);
          setCompanyNameData([]);
          tempState["areaManager"] = [];
          tempState["groupName"] = [];
          tempState["companyName"] = [];
          setEmpFilter((prevState) => ({
            ...prevState,
            ...tempState
          }));
          break;
        case "groupName":
          setCompanyNameData([]);
          tempState["groupName"] = [];
          tempState["companyName"] = [];
          setEmpFilter((prevState) => ({
            ...prevState,
            ...tempState
          }));
          break;

        default:
          break;
      }
    }
    if (name !== "groupName" && name !== "companyName") {
      handleGetGroupNamesData(sapCode);
    }
  };
  const handleCustMultiSelectChange = (e, name) => {
    if (name === "customerUsers") {
      if (e) {
        setSelectedCustomerUser(e);
        let findGroupOrgadata = customerUsersData.find((x) => x.id === e);
        let tempState = custFilter;
        tempState["groupName"] = [findGroupOrgadata.groupid];
        tempState["custOrganization"] = [findGroupOrgadata.organizationid];
        setCustFilter((prevState) => ({
          ...prevState,
          ...tempState
        }));
        setCustGroupNameData([
          {
            id: findGroupOrgadata.groupid,
            name: findGroupOrgadata.groupname
          }
        ]);
        setCustOrganizationData([
          {
            id: findGroupOrgadata.organizationid,
            name: findGroupOrgadata.organizationName,
            sapCode: findGroupOrgadata.organizationSapcode
          }
        ]);
      } else {
        setSelectedCustomerUser(null);
      }
    } else {
      let tempState = custFilter;
      tempState[name] = e;
      setCustFilter((prevState) => ({
        ...prevState,
        ...tempState
      }));
      if (e.length > 0) {
        switch (name) {
          case "groupName":
            handleGetCustOrganisationByGroupIdsData(e);
            break;
          default:
            break;
        }
      } else {
        switch (name) {
          case "groupName":
            setCustOrganizationData([]);
            tempState["custOrganization"] = [];
            setCustFilter((prevState) => ({
              ...prevState,
              ...tempState
            }));
            break;
          default:
            break;
        }
      }
    }
  };
  const handleGetEmpGeneralManagerData = () => {
    EmployeeFilterService.empGeneralManagerAPI()
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finalResponse = response.data.responseData;
          if (finalResponse.length > 0) {
            setGeneralManagerData(finalResponse);
          } else {
            setGeneralManagerData([]);
          }
        }
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };

  const handleGetRegionalManagerData = (ids) => {
    let inputData = {
      id: ids ? ids.toString() : "",
      email: loginDetails.email
    };
    EmployeeFilterService.empRegionalManagerAPI(inputData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finalResponse = response.data.responseData;
          if (finalResponse.length > 0) {
            setRegionalManagerData(finalResponse);
          } else {
            setRegionalManagerData([]);
          }
        }
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };
  const handleGetAreaManagerData = (ids) => {
    let inputData = {
      id: ids ? ids.toString() : "",
      email: loginDetails.email
    };
    EmployeeFilterService.empAreaManagerAPI(inputData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finalResponse = response.data.responseData;
          if (finalResponse.length > 0) {
            setAreaManagerData(finalResponse);
          } else {
            setAreaManagerData([]);
          }
        }
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };
  const handleGetGroupNamesData = (sapCodeIds) => {
    let inputData = {
      id: "",
      email: loginDetails.email,
      sapCodes: sapCodeIds.toString()
    };
    EmployeeFilterService.empGroupNameAPI(inputData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finalResponse = response.data.responseData;
          if (finalResponse.length > 0) {
            setGroupNameData(finalResponse);
          } else {
            setGroupNameData([]);
          }
        }
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };
  const handleGetCompanyNamesData = (ids) => {
    let inputData = {
      id: ids.toString(),
      email: loginDetails.email
    };
    EmployeeFilterService.empCompanyNameAPI(inputData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finalResponse = response.data.responseData;
          if (finalResponse.length > 0) {
            setCompanyNameData(finalResponse);
          } else {
            setCompanyNameData([]);
          }
        }
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };

  const handleGetCustGroupNameData = () => {
    EmployeeFilterService.custGroupNameAPI()
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finalResponse = response.data.responseData;
          if (finalResponse.length > 0) {
            setCustGroupNameData(finalResponse);
          } else {
            setCustGroupNameData([]);
          }
        }
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };
  const handleGetCustOrganisationByGroupIdsData = (ids) => {
    let inputData = {
      id: ids.toString()
    };
    EmployeeFilterService.custOrganisationByGroupIdAPI(inputData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finalResponse = response.data.responseData;
          if (finalResponse.length > 0) {
            setCustOrganizationData(finalResponse);
          } else {
            setCustOrganizationData([]);
          }
        }
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };
  const handleGetCustomerUsersByOrganisationIdsData = () => {
    EmployeeFilterService.custUsersByOrganisationIdAPI()
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finalResponse = response.data.responseData;
          if (finalResponse.length > 0) {
            setCustomerUsersData(finalResponse);
          } else {
            setCustomerUsersData([]);
          }
        }
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };
  const handleRemoveAllFilter = () => {
    if (isEmployee) {
      setEmpFilter({
        generalManager: [],
        regionalManager: [],
        areaManager: [],
        groupName: [],
        companyName: []
      });
      setRegionalManagerData([]);
      setAreaManagerData([]);
      setGroupNameData([]);
      setCompanyNameData([]);
      applyFilter({
        generalManager: [],
        regionalManager: [],
        areaManager: [],
        groupName: [],
        companyName: []
      });
    } else {
      setCustFilter({
        groupName: [],
        custOrganization: []
      });
      setCustOrganizationData([]);
      setCustomerUsersData([]);
      setSelectedCustomerUser(null);
      applyFilter({
        groupName: [],
        custOrganization: []
      });
    }
  };

  function getEmpSapCodesForFilters(filter, data, check) {
    if (check === "Emp") {
      return filter
        .map((id) => {
          let item = data.find((x) => x.id === id);
          return item ? item.employee_Sapcode : null;
        })
        .filter((sapcode) => sapcode !== null);
    } else {
      return filter
        .map((id) => {
          let item = data.find((x) => x.id === id);
          return item ? item.saP_Organization : null;
        })
        .filter((sapcode) => sapcode !== null);
    }
  }
  function getCustSapCodesForFilters(filter, data) {
    return filter
      .map((id) => {
        let item = data.find((x) => x.id === id);
        return item ? item.sapCode : null;
      })
      .filter((sapcode) => sapcode !== null);
  }
  const handleApplyFilter = () => {
    let getSapCodeforFilters = {};
    if (isEmployee) {
      getSapCodeforFilters = {
        generalManager: getEmpSapCodesForFilters(
          empFilter.generalManager,
          generalManagerData,
          "Emp"
        ),
        regionalManager: getEmpSapCodesForFilters(
          empFilter.regionalManager,
          regionalManagerData,
          "Emp"
        ),
        areaManager: getEmpSapCodesForFilters(
          empFilter.areaManager,
          areaManagerData,
          "Emp"
        ),
        groupName: empFilter.groupName,
        custOrganization: getEmpSapCodesForFilters(
          empFilter.companyName,
          companyNameData,
          "organization"
        )
      };
    } else {
      getSapCodeforFilters = {
        groupName: custFilter.groupName,
        custOrganization: getCustSapCodesForFilters(
          custFilter.custOrganization,
          custOrganizationData
        )
      };
    }
    applyFilter(getSapCodeforFilters);
  };
  return (
    <Fragment>
      <div>
        <Drawer
          title={isEmployee ? "Employee Filters" : "Customer Filters"}
          className="global_filter"
          placement="right"
          closable={false}
          onClose={showFilterDrawer}
          open={isGlobalFilter}
          drawerStyle={{ width: "100%", overflow: "hidden" }}
          bodyStyle={{ padding: 0 }}
          extra={
            <Space>
              <Button onClick={showFilterDrawer} className="fliter_close">
                <CloseOutlined />
              </Button>
            </Space>
          }
        >
          <div>
            <div className="content drawer-content">
              <div className="content-body">
                {isEmployee ? (
                  <div className="container drawer-select filter_dropdown">
                    <div>
                      <label className="filterLbl">
                        Select General Manager:
                      </label>
                      <Select
                        mode="multiple"
                        showArrow
                        showSearch
                        allowClear
                        placeholder="Please select"
                        onChange={(selectedIds) => {
                          const selectedSapcodes = selectedIds.map((id) => {
                            const selectedItem = generalManagerData.find(
                              (item) => item.id === id
                            );
                            return selectedItem.employee_Sapcode;
                          });

                          handleEmpMultiSelectChange(
                            selectedIds,
                            selectedSapcodes,
                            "generalManager"
                          );
                        }}
                        value={empFilter.generalManager}
                        optionFilterProp="children"
                        disabled={
                          loginDetails.designation === "General Manager" ||
                          loginDetails.designation === "Regional Manager" ||
                          loginDetails.designation === "Area Manager"
                            ? true
                            : false
                        }
                      >
                        {generalManagerData.map((item) => {
                          return (
                            <Option value={item.id} key={item.id}>
                              {item.value}
                            </Option>
                          );
                        })}
                      </Select>
                    </div>
                    <div>
                      <label className="filterLbl">
                        Select Regional Manager:
                      </label>
                      <Select
                        showSearch
                        mode="multiple"
                        showArrow
                        allowClear
                        placeholder="Please select"
                        onChange={(selectedIds) => {
                          const selectedSapcodes = selectedIds.map((id) => {
                            const selectedItem = regionalManagerData.find(
                              (item) => item.id === id
                            );
                            return selectedItem.employee_Sapcode;
                          });

                          handleEmpMultiSelectChange(
                            selectedIds,
                            selectedSapcodes,
                            "regionalManager"
                          );
                        }}
                        value={empFilter.regionalManager}
                        optionFilterProp="children"
                        disabled={
                          loginDetails.designation === "Regional Manager" ||
                          loginDetails.designation === "Area Manager"
                            ? true
                            : false
                        }
                      >
                        {regionalManagerData.map((item) => {
                          return (
                            <Option value={item.id} key={item.id}>
                              {item.value}
                            </Option>
                          );
                        })}
                      </Select>
                    </div>
                    <div>
                      <label className="filterLbl">Select Area Manager:</label>
                      <Select
                        showSearch
                        mode="multiple"
                        showArrow
                        allowClear
                        placeholder="Please select"
                        onChange={(selectedIds) => {
                          const selectedSapcodes = selectedIds.map((id) => {
                            const selectedItem = areaManagerData.find(
                              (item) => item.id === id
                            );
                            return selectedItem.employee_Sapcode;
                          });

                          handleEmpMultiSelectChange(
                            selectedIds,
                            selectedSapcodes,
                            "areaManager"
                          );
                        }}
                        value={empFilter.areaManager}
                        optionFilterProp="children"
                        disabled={
                          loginDetails.designation === "Area Manager"
                            ? true
                            : false
                        }
                      >
                        {areaManagerData.map((item) => {
                          return (
                            <Option value={item.id} key={item.id}>
                              {item.value}
                            </Option>
                          );
                        })}
                      </Select>
                    </div>
                    <div>
                      <label className="filterLbl">Select Group:</label>
                      <Select
                        showSearch
                        mode="multiple"
                        showArrow
                        allowClear
                        placeholder="Please select"
                        onChange={(e) =>
                          handleEmpMultiSelectChange(e, "", "groupName")
                        }
                        value={empFilter.groupName}
                        optionFilterProp="children"
                      >
                        {groupNameData.map((item) => {
                          return (
                            <Option value={item.id} key={item.id}>
                              {item.value}
                            </Option>
                          );
                        })}
                      </Select>
                    </div>
                    <div>
                      <label className="filterLbl">Select Company:</label>
                      <Select
                        showSearch
                        mode="multiple"
                        showArrow
                        allowClear
                        placeholder="Please select"
                        onChange={(e) =>
                          handleEmpMultiSelectChange(e, "", "companyName")
                        }
                        value={empFilter.companyName}
                        optionFilterProp="children"
                      >
                        {companyNameData.map((item) => {
                          return (
                            <Option value={item.id} key={item.id}>
                              {item.value}
                            </Option>
                          );
                        })}
                      </Select>
                    </div>
                  </div>
                ) : (
                  <div className="container drawer-select filter_dropdown">
                    <div>
                      <label className="filterLbl">Select Group:</label>
                      <Select
                        showSearch
                        mode="multiple"
                        showArrow
                        allowClear
                        placeholder="Please select"
                        onChange={(e) =>
                          handleCustMultiSelectChange(e, "groupName")
                        }
                        value={custFilter.groupName}
                        optionFilterProp="children"
                      >
                        {custGroupNameData.map((item) => {
                          return (
                            <Option value={item.id} key={item.id}>
                              {item.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </div>
                    <div>
                      <label className="filterLbl">Select Organization:</label>
                      <Select
                        showSearch
                        mode="multiple"
                        showArrow
                        allowClear
                        placeholder="Please select"
                        onChange={(e) =>
                          handleCustMultiSelectChange(e, "custOrganization")
                        }
                        value={custFilter.custOrganization}
                        optionFilterProp="children"
                      >
                        {custOrganizationData.map((item) => {
                          return (
                            <Option value={item.id} key={item.id}>
                              {item.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </div>
                    <div>
                      <label className="filterLbl">Select Contact:</label>
                      <Select
                        showSearch
                        allowClear
                        placeholder="Please select"
                        onChange={(e) =>
                          handleCustMultiSelectChange(e, "customerUsers")
                        }
                        value={selectedCustomerUser}
                        optionFilterProp="children"
                      >
                        {customerUsersData.map((item) => {
                          return (
                            <Option value={item.id} key={item.id}>
                              {item.fullName}
                            </Option>
                          );
                        })}
                      </Select>
                    </div>
                  </div>
                )}

                <div className="filter-drawer-buttons container global_filter_btn">
                  <Button type="primary apply_flt" onClick={handleApplyFilter}>
                    Apply Filter
                  </Button>
                  <Button
                    type="primary reset_flt"
                    onClick={handleRemoveAllFilter}
                  >
                    Remove Filter
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Drawer>
      </div>
    </Fragment>
  );
};

export default GlobalFilterDrawer;
