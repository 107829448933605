import React, { Fragment, useEffect, useState } from "react";
import { InfoCircleTwoTone } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { Table } from "antd";
import moment from "moment";
import formatNumber from "../../../../utils/formatNumber";

const InvoiceDetailsTbl = ({
  tabTableData,
  isLoading,
  OnhandleTableSortColumn
}) => {
  const navigate = useNavigate();

  const [totalRecords, setTotalRecords] = useState(0);
  const [pageNo, setPageNo] = useState(1);

  useEffect(() => {
    if (tabTableData.invoiceWisePaymentList?.length > 0) {
      setTotalRecords(tabTableData.totalRecords);
      setPageNo(Number(tabTableData.currentPage));
    } else {
      setTotalRecords(0);
      setPageNo(1);
    }
  }, [tabTableData.invoiceWisePaymentList]);

  const handleTableAscDes = (pagination, filters, sorter) => {
    OnhandleTableSortColumn({
      currentPage: pagination.current
    });
  };

  const handleOverviewDetailsPage = (rowData) => {
    navigate(`/overviewDetails/all/ZPF4419`);
  };

  const tableColumns = [
    {
      title: "Customer Name",
      dataIndex: "customerOrganization",
      sorter: (a, b) => a.customerOrganization - b.customerOrganization,
      showSorterTooltip: false,
      width: 250,
      render: (_i, row) => {
        return (
          <div>
            <Link to={`/detailsView/all/2000243`}>
              <label className="cust_po_no_lbl">
                {row.customerOrganization}
              </label>
            </Link>
          </div>
        );
      }
    },
    {
      title: "Invoice No",
      dataIndex: "invoiceNumber",
      sorter: (a, b) =>
        parseFloat(a.invoiceNumber.replace(/,/g, "")) -
        parseFloat(b.invoiceNumber.replace(/,/g, "")),
      showSorterTooltip: false,
      width: 120
    },
    {
      title: "Invoice Date",
      dataIndex: "invoiceDate",
      sorter: (a, b) => new Date(a.invoiceDate) - new Date(b.invoiceDate),
      showSorterTooltip: false,
      width: 120,
      render: (_i, row) => {
        return (
          <div>
            {row.invoiceDate
              ? moment(row.invoiceDate).format("DD-MM-YYYY")
              : "N/A"}
          </div>
        );
      }
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      sorter: (a, b) => new Date(a.dueDate) - new Date(b.dueDate),
      showSorterTooltip: false,
      width: 120,
      render: (_i, row) => {
        return (
          <div>
            {row.dueDate ? moment(row.dueDate).format("DD-MM-YYYY") : "N/A"}
          </div>
        );
      }
    },
    {
      title: "Pending Amount LCY",
      dataIndex: "pendingAmtLcy",
      sorter: (a, b) =>
        parseFloat(a.pendingAmtLcy.replace(/,/g, "")) -
        parseFloat(b.pendingAmtLcy.replace(/,/g, "")),
      showSorterTooltip: false,
      width: 160,
      render: (text, record) =>
        `${record.currency} ${formatNumber(
          parseFloat(record.totalPendingAmount_LCY.replace(/,/g, ""))
        )}`
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 80,
      fixed: "right",
      render: (_i, row) => (
        <div
          className="tbl_info_details"
          onClick={() => handleOverviewDetailsPage(row)}
        >
          <InfoCircleTwoTone />
        </div>
      )
    }
  ];

  return (
    <Fragment>
      <Table
        bordered
        scroll={{ x: 900 }}
        className="dashboard_tables"
        dataSource={tabTableData.invoiceWisePaymentList}
        loading={isLoading}
        columns={tableColumns}
        onChange={handleTableAscDes}
        pagination={{
          total: totalRecords,
          pageNo: pageNo,
          pageSize: 10,
          responsive: true,
          showSizeChanger: false,
          current: pageNo,
          onChange: (pageNum) => setPageNo(pageNum)
        }}
      />
    </Fragment>
  );
};

export default InvoiceDetailsTbl;
