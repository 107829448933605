import React, { Fragment } from "react";
import { Table } from "antd";
import formatNumber from "../../../../utils/formatNumber";

const CurrencyOutstandingTbl = ({ tableData, totalLCY, isLoading }) => {
  const tableColumns = [
    {
      title: "Currency",
      dataIndex: "currency",
      showSorterTooltip: false,
      sorter: (a, b) => a.currency.localeCompare(b.currency),
      width: 120
    },
    {
      title: "Pending Amount",
      dataIndex: "totalPendingAmount",
      showSorterTooltip: false,
      sorter: (a, b) =>
        parseFloat(a.totalPendingAmount?.replace(/,/g, "")) -
        parseFloat(b.totalPendingAmount?.replace(/,/g, "")),
      width: 150,
      render: (value) => formatNumber(parseFloat(value.replace(/,/g, "")))
    },
    {
      title: "Pending Amount LCY",
      dataIndex: "totalPendingAmount_LCY",
      showSorterTooltip: false,
      sorter: (a, b) =>
        parseFloat(a.totalPendingAmount_LCY?.replace(/,/g, "")) -
        parseFloat(b.totalPendingAmount_LCY?.replace(/,/g, "")),
      width: 150,
      render: (value) => formatNumber(parseFloat(value?.replace(/,/g, "")))
    }
  ];

  return (
    <Fragment>
      <div className="cust_outstandingTbl">
        <Table
          bordered
          scroll={{ x: 500 }}
          className="customer_tbl"
          dataSource={tableData}
          columns={tableColumns}
          pagination={tableData.length > 0 ? false : true}
          loading={isLoading}
          summary={() => {
            return (
              <Table.Summary.Row className="tbl_summary">
                <Table.Summary.Cell colSpan={1} style={{ fontWeight: "bold" }}>
                  Total
                </Table.Summary.Cell>
                <Table.Summary.Cell />
                <Table.Summary.Cell>
                  {formatNumber(totalLCY)}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            );
          }}
        />
      </div>
    </Fragment>
  );
};

export default CurrencyOutstandingTbl;
